<template>
  <div
    class="richText ql-editor"
    v-html="content"
  ></div>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.richText {
  overflow: hidden;
  ::v-deep {
    img {
      max-width: 100%;
      vertical-align: bottom;
    }
    a {
      color: $sub-color-2;
      text-decoration: underline;
    }
  }
}
</style>