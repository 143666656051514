<template>
  <div class="SJXBack">
    <span
      class="prev"
      @click="goOther('prev')"
    >上一条</span>
    <span
      class="back"
      @click="goOther('back')"
    >返回列表</span>
    <span
      class="next"
      @click="goOther('next')"
    >下一条</span>
  </div>
</template>

<script>
export default {
  methods: {
    goOther(type) {
      if (type === 'back') {
        this.$router.go(-1);
        return;
      }
      this.$emit('goOtherDetail', type);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/ShuJiuXiang/theme.scss';
.SJXBack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    width: 120px;
    height: 44px;
    border: 1px solid $border-color-4;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $sub-color-2;
    border-radius: 2px;
    @media screen and (min-width: $hover-point) {
      transition: 300ms;
      &:hover {
        background: #fff;
        background: #fff;
        color: $sub-color-2;
      }
    }
  }
  .back {
    border-color: $sub-color-2;
    background: $sub-color-2;
    color: #fff;
  }
  @media screen and (max-width: $res-point-8) {
    > span {
      width: 84px;
    }
  }
}
</style>