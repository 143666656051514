<template>
  <div class="news-detail-box">
    <PageContent>
      <div
        class="nd-bg"
        ref="newsDetailMark"
      ></div>
      <div class="content">
        <div class="titleBox">
          <p class="en">{{ detailData.en }}</p>
          <p class="ch">{{ detailData.ch }}</p>
        </div>
        <div v-loading="loading">
          <div v-if="show">
            <p class="nd-title">{{ detailData.title }}</p>
            <p class="date"><span class="y">{{ date.year }}</span><span class="m">{{ date.month }}</span></p>
            <div class="divider"></div>
            <RichText :content="detailData.desc" />
            <div
              v-if="isNearMobile"
              class="backBox"
            >
              <div class="divider"></div>
              <SJXBack />
            </div>
          </div>
          <el-empty v-else />
        </div>
      </div>
    </PageContent>
    <SideNav
      :navList="navList"
      @changeActiveNav="changeActiveNav"
      v-if="!isNearMobile"
      :active="active"
      :always="true"
    />
  </div>
</template>

<script>
import RichText from '@/components/RichText';
import PageContent from '../components/Layout/PageContent.vue';
import _ from 'lodash';
import SideNav from '../components/SideNav';
import { mapState } from 'vuex';
import { SJXBack } from '@/components/BackList';
import {
  getDetail,
  getNextNews,
  getLastNews,
  getNextActivity,
  getLastActivity,
  getActivityDetail,
} from '@/api/ShuJiuXiang/news';
import { Message } from 'element-ui';

export default {
  components: {
    RichText,
    PageContent,
    SideNav,
    SJXBack,
  },
  data() {
    return {
      active: 'back',
      detailData: {},
      loading: false,
      navList: [
        { text: '上一条', key: 'prev' },
        { text: '返回列表', key: 'back' },
        { text: '下一条', key: 'next' },
      ],
    };
  },
  mounted() {
    this.init();
    this.getData();
    window.addEventListener('resize', _.debounce(this.init, 50));
  },
  destroyed() {
    window.removeEventListener('resize', this.init);
  },
  computed: {
    ...mapState(['isMobile', 'isNearMobile']),
    show() {
      return JSON.stringify(this.detailData) !== '{}';
    },
    date() {
      const { createTime = '' } = this.detailData;
      return {
        year: createTime ? createTime.slice(0, 4) : '',
        month: createTime ? createTime.slice(5, 10).replace('-', '.') : '',
      };
    },
  },
  methods: {
    init() {
      const { height } = this.$refs.newsDetailMark?.getBoundingClientRect() || {};
      this.$store.commit('HEARDER_SCROLL_MARKS', {
        [this.$route.name]: {
          height,
          isBlack: true,
        },
      });
    },
    async changeActiveNav(key) {
      const { type, id } = this.$route.params;
      if (key === 'back') {
        this.$router.push(`/sjx/news/${type}`);
        return;
      }
      let res;
      if (key === 'prev') {
        if (type === 'notice') {
          res = await getLastNews({ news_id: id });
        } else {
          res = await getLastActivity({ activity_id: id });
        }
      }
      if (key === 'next') {
        if (type === 'notice') {
          res = await getNextNews({ news_id: id });
        } else {
          res = await getNextActivity({ activity_id: id });
        }
      }
      if (res) {
        this.$router.push(`/sjx/news/detail/${type}/${res.newsId}`);
      } else {
        Message.warning('没有了！');
      }
    },
    // 基本信息
    async getData() {
      this.loading = true;
      const { type } = this.$route.params;
      let res = {};
      try {
        if (type === 'notice') {
          res = await getDetail({
            news_id: this.$route.params.id,
          });
        } else {
          res = await getActivityDetail({
            activity_id: this.$route.params.id,
          });
        }
        this.loading = false;
        this.detailData = {
          en: type === 'notice' ? res.newsEnglish : res.activityEnglish,
          ch: type === 'notice' ? res.newsChinese : res.activityChinese,
          title: type === 'notice' ? res.newsTitle : res.activityTitle,
          createTime: res.createTime,
          desc: type === 'notice' ? res.newsContent : res.activityContent,
        };
      } catch (error) {
        this.loading = false;
      }
    },
  },
  watch: {
    $route() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '@/styles/mixin.scss';
@import '@/styles/ShuJiuXiang/theme.scss';
.news-detail-box {
  .content {
    width: 1000px;
    margin: 0 auto;
    max-width: 90%;
    position: relative;
    padding-top: 135px;
    padding-bottom: math.percentage(math.div(218, 1920));
  }
  ::v-deep .pageContent {
    min-height: 100vh;
    position: relative;
    padding: 0;
  }
  .nd-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: math.percentage(math.div(910, 1920));
    height: 280px;
    background: url('../../../assets/ShuJiuXiang/detail-bg.jpg') no-repeat;
    background-size: cover;
  }
  .date {
    margin: 52px 0 34px;
    > span {
      display: block;
      color: $text-color-2;
    }
    .y {
      font-size: 16px;
      line-height: math.div(18, 16);
    }
    .m {
      margin-top: 4px;
      font-size: 32px;
      line-height: math.div(36, 32);
    }
  }
  .divider {
    height: 1px;
    background: $sub-color-2;
    opacity: 0.2;
  }
  ::v-deep {
    .richText {
      margin-top: 54px;
    }
    .SJXBack {
      padding-top: 34px;
    }
  }
  .backBox {
    padding-top: 54px;
  }
}
.titleBox {
  padding-bottom: 146px;
  color: #fff;
  .ch,
  .en {
    @include text-nowrap;
    font-size: 24px;
    line-height: math.div(32, 24);
  }
  .en {
    margin-bottom: 16px;
  }
}
.nd-title {
  font-size: 48px;
  line-height: math.div(64, 48);
  color: $sub-color-2;
}
@media screen and (max-width: $res-point-3) {
  .news-detail-box {
    .content {
      width: 800px;
    }
  }
}
@media screen and (max-width: $res-point-8) {
  .news-detail-box {
    .nd-bg {
      height: 224px;
      width: 100%;
    }
    .content {
      padding-top: 108px;
    }
    .date {
      margin: 42px 0 34px * 0.8;
      .y {
        font-size: 14px;
      }
      .m {
        font-size: 26px;
      }
    }
    ::v-deep {
      .richText {
        margin-top: 54px * 0.8;
      }
    }
  }
  .titleBox {
    padding-bottom: 100px;
    .ch,
    .en {
      font-size: 20px;
    }
    .en {
      margin-bottom: 12px;
    }
  }
  .nd-title {
    font-size: 30px;
  }
}
@media screen and (max-width: $res-point-9) {
  .nd-title {
    font-size: 24px;
  }
  .titleBox {
    padding-bottom: 80px;
  }
  .news-detail-box {
    .date {
      margin: 30px 0 20px;
      .y {
        font-size: 12px;
      }
      .m {
        font-size: 20px;
      }
    }
    ::v-deep {
      .richText {
        margin-top: 30px;
      }
      .SJXBack {
        padding-top: 10px;
      }
    }
    .backBox {
      padding-top: 45px;
    }
  }
}
</style>